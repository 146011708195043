import serviceApi from "@/core/serviceApi";

export default class serviceAnalyst {
	/**
	 * Маршруты для запросов
	 * @property {String} sendEvent - Отправить событие для аналитики
	 */
	static _requestRouts = {
		sendEvent: 'analyst/event/set',
	};


	/**
	 * Отправка ответа
	 * @async
	 * @param {object} formData
	 * @param actionAfter
	 * @param actionAfterError
	 * @returns {object}
	 */
	static async sendEvent(formData, actionAfter = () => {}, actionAfterError = () => {}) {
		let postData = {
			category: 'web_app',
			event: formData.event,
		};

		if (formData.data) {
			postData.data = formData.data;
		}

		try {
			let {data = {}} = await serviceApi.post(this._requestRouts.sendEvent, postData);

			actionAfter(data);
		} catch (error) {
			actionAfterError(error);
		}
	}
}