<template>
	<div :class="['list', {'pb': visibleNext}]">
		<div class="list__block">
			<template
				v-for="(item, index) in content"
				:key="index"
			>
				<ListItem
					@click="visibleClick(item)"
					:list="item.list"
					:title="item.title"
					:text="item.text"
					:tel="item.tel"
					:class="{'_active': activeItem.id === item.id}"
				/>
			</template>
		</div>
		<div :class="['container-btn', {'_hide': !visibleNext}]">
			<button class="btn btn-default" @click="onSelectHall">
				Выбрать
			</button>
		</div>
	</div>
</template>

<script>
import ListItem from "@/components/ ListCenters/ListItem.vue";
import {ref} from "vue";

export default {
	name: "ListContainer",
	components: {ListItem},
	props: {
		content: {
			type: Object,
			default: () => ({})
		},
	},
	setup(props, context) {
		const activeItem = ref({});
		const visibleNext = ref(false);
		
		const list = ref({})
		const visibleClick = (item) => {
			activeItem.value = item;
			list.value = item;
			visibleNext.value = true;
		}
		
		const onSelectHall = () => {
			context.emit('onSelectHall', activeItem.value);
		}
		
		return {
			activeItem,
			list,
			visibleNext,
			visibleClick,
			onSelectHall
		}
	}
}
</script>