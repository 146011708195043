<script setup>
import {useRoute, useRouter} from "vue-router";
import {watch} from "vue";
import serviceTelegram from "@/core/services/serviceTelegram";
import serviceAnalyst from "@/core/services/serviceAnalyst";
import {useQuestion} from "@/composition/useQuestion";
import {useStore} from "vuex";

const {
	currentTab,
	changeTab
} = useQuestion();

const route = useRoute();
const router = useRouter();
const store = useStore();

watch(() => route.name, () => {
	if (route.name === 'Home' || route.name === 'FinishPage') {
		serviceTelegram.setBackButton(false);
	} else {
		serviceTelegram.setBackButton(true);
	}
});

const toBack = () => {
	if (route.name === 'SurveyQuestions') {
		if (Number(currentTab.value) === 0) {
			serviceAnalyst.sendEvent({
				event: 'route_back',
				data: {
					from_page_title_ru: 'Вопросы',
					from_page_title_en: 'Questions',
					to_page_title_ru: 'Главная',
					to_page_title_en: 'Home',
				}
			});
			router.push({ name: 'Home' });
		} else {
			changeTab(Number(currentTab.value) - 1)
		}
	} else if (route.name === 'SurveyPageNoMoscow') {
		serviceAnalyst.sendEvent({
			event: 'route_back',
			data: {
				from_page_title_ru: 'Не Москва',
				from_page_title_en: 'NoMoscow',
				to_page_title_ru: 'Вопросы',
				to_page_title_en: 'Questions',
			}
		});
		
		changeTab(0);
	} else if (route.name === 'ListCenters') {
		if (currentTab.value === undefined) {
			serviceAnalyst.sendEvent({
				event: 'route_back',
				data: {
					from_page_title_ru: 'Список клубов',
					from_page_title_en: 'ListCenters',
					to_page_title_ru: 'Вопросы',
					to_page_title_en: 'Questions',
				}
			});
			store.state.currentHall = {};
			changeTab(2);
		}
	} else if (route.name === 'SubscriptionPage') {
		serviceAnalyst.sendEvent({
			event: 'route_back',
			data: {
				from_page_title_ru: 'Подписка',
				from_page_title_en: 'Subscription',
				to_page_title_ru: 'Список залов',
				to_page_title_en: 'ListCenters',
			}
		});
		
		router.push({ name: 'ListCenters'});
	} else if (route.name === 'BookingPage') {
		serviceAnalyst.sendEvent({
			event: 'route_to',
			data: {
				from_page_title_ru: 'Отправка заявки',
				from_page_title_en: 'BookingPage',
				to_page_title_ru: 'Список клубов',
				to_page_title_en: 'ListCenters',
			}
		});
		
		store.state.currentHall = {};
		router.push({ name: 'ListCenters'});
	}
};

serviceTelegram.offClickBackButton(toBack);
serviceTelegram.setClickBackButton(toBack);
</script>

<template>
	<router-view/>
</template>