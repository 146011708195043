import axios from 'axios';
import serviceTelegram from "@/core/services/serviceTelegram";

/**
 * Сервис по работе с апи
 * в случае недействительного токена - выполнить logout
 * @property {function} createApi - создать объект axios
 * @property {function} get - сделать гет запрос
 * @property {function} post - сделать пост запрос
 */
class serviceApi {
	/**
	 * Cоздать объект axios
	 * @static
	 * @returns {object}
	 */
	static createApi(url = process.env.VUE_APP_BACKEND_HOST, auth = process.env.VUE_APP_BACKEND_INIT) {
		console.log(serviceTelegram.initData());
		let api = axios.create({
			baseURL: url,
			headers: {
				'Authorization': auth !== 'null'? auth : serviceTelegram.initData()
			}
		});
		return api;
	}

	/**
	 * Сделать гет запрос
	 * @static
	 * @async
	 * @param {string} route - маршрут
	 * @param {object} params - парметры
	 * @param {object} reqConfig - конфигурация запроса
	 * @returns {Promise|Error}
	 */
	static async get(route = "", params = {}, reqConfig = {}) {
		let api = this.createApi();
		try {
			let response = await api.get(route, params, reqConfig);
			return response;
		}
		catch(error) {
			return this.createError(error);
		}
	}

	/**
	 * Сделать пост запрос
	 * @static
	 * @async
	 * @param {string} route - маршрут
	 * @param {object} postData - тело запроса
	 * @param {object} reqConfig - конфигурация запроса
	 * @returns {Promise|Error}
	 */
	static async post(route = "", postData = {}, reqConfig = {}) {
		let api = this.createApi();
		try {
			let response = await api.post(route, postData, reqConfig);
			return response;
		}
		catch(error) {
			return this.createError(error);
		}
	}

	/**
	 * Сделать пут запрос
	 * @static
	 * @async
	 * @param {string} route - маршрут
	 * @param {object} postData - тело запроса
	 * @param {object} reqConfig - конфигурация запроса
	 * @returns {Promise|Error}
	 */
	static async put(route = "", postData = {}, reqConfig = {}) {
		let api = this.createApi();
		try {
			let response = await api.put(route, postData, reqConfig);
			return response;
		}
		catch(error) {
			return this.createError(error);
		}
	}

	/**
	 * Сделать запрос на удаление
	 * @static
	 * @async
	 * @param {string} route - маршрут
	 * @param {object} params - парметры
	 * @param {object} reqConfig - конфигурация запроса
	 * @returns {Promise|Error}
	 */
	static async delete(route = "", params = {}, reqConfig = {}) {
		let api = this.createApi();
		try {
			let response = await api.delete(route, params, reqConfig);
			return response;
		}
		catch(error) {
			return this.createError(error);
		}
	}

	/**
	 * Обработать ошибку
	 * @static
	 * @async
	 * @param {Object} error - бъект ошибки
	 */
	static async createError(error = {}) {
		throw error;
	}
}

export default serviceApi;