<script setup>
import serviceTelegram from "@/core/services/serviceTelegram";
import serviceAnalyst from "@/core/services/serviceAnalyst";

const onClose = () => {
	serviceAnalyst.sendEvent({
		event: 'route_back',
		data: {
			from_page_title_ru: 'Последняя страница',
			from_page_title_en: 'FinishPage',
			to_page_title_ru: 'Внешний сайт',
			to_page_title_en: 'Site',
		}
	});
	window.open('https://spiritfit.ru/', '_blank');
	serviceTelegram.close();
};
</script>

<template>
	<div class="container">
		<div class="wrap">
			<div class="pulse">
				<div class="pulse__ring"></div>
				<div class="pulse__ring"></div>
				<div class="pulse__ring"></div>
			</div>
			<div class="fs-42 ff-bold mb_10">Готово!</div>
			<div class="c-gray">Сейчас мы свяжемся с Вами для уточнения деталей, кстати, можно бесплатно взять тренера для пробной тренировки.</div>
		</div>
		<div class="container-btn">
			<div class="btn btn-border" @click="onClose">
				Подробнее о клубе
			</div>
		</div>
	</div>
</template>