import { createStore } from 'vuex'

export default createStore({
    state: {
        isMounted: false,
        startPrice: 0,
        currentPrice: 0,
        currentQuestion: 0,
        finalPrice: 0,
        currentAbonement: {
            id: null
        },
        answers: {},
        currentHall: {},
    },
    mutations: {
        mount(state) {
            state.isMounted = true
        },
    },
})
