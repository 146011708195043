import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueTheMask from 'vue-the-mask'

import '@/assets/scss/main.scss'
import serviceTelegram from "@/core/services/serviceTelegram";

serviceTelegram.tg.expand();
serviceTelegram.tg.enableClosingConfirmation();

createApp(App).use(router).use(store).use(VueTheMask).mount('#app')
