<script setup>
import {useRouter} from "vue-router";
import {utilsBase} from "@/utils/utilsBase";
import {useStore} from "vuex";
import serviceAnalyst from "@/core/services/serviceAnalyst";
import BasePreloader from "@/components/Base/BasePreloader.vue";
import serviceAbonement from "@/core/services/serviceAbonement";
import {ref} from "vue";

const store = useStore();
const router = useRouter();

const isLoading = ref(false);
const isFirstTraining = ref(false);
const abonementData = ref({});

const getAbonement = () => {
	isLoading.value = true;
	serviceAbonement.getAbonement((data) => {
		if (data.id !== null) {
			abonementData.value = data;
			store.state.currentAbonement.id = data.id;
			store.state.finalPrice = data.basePrice
			isFirstTraining.value = false;
		} else {
			isFirstTraining.value = true;
		}
		
		isLoading.value = false;
	});
}

if (utilsBase.isEmptyObject(store.state.currentHall)) {
	router.push({ name: 'ListCenters'});
} else {
	getAbonement();
}

const onSubmit = () => {
	serviceAnalyst.sendEvent({
		event: 'route_to',
		data: {
			from_page_title_ru: 'Подписка',
			from_page_title_en: 'Subscription',
			to_page_title_ru: 'Отправка заявки',
			to_page_title_en: 'BookingPage',
		}
	});
	router.push({ name: 'BookingPage' });
}
</script>

<template>
	<div class="">
		<BasePreloader v-if="isLoading"/>
		<template v-else>
			<div class="container">
				<div class="wrap" v-if="isFirstTraining">
					<div class="logo is-small">
						<img src="/assets/img/logo.png" alt="">
					</div>
					<div class="fs-22 ff-bold pb_30">
						<span class="text-gradient">
							Пробная тренировка -
						</span>
						<br/>отличная возможность проверить, твоё или не твоё.
					</div>
					<div class="c-gray fs-16 lh-base px-16">Неограниченный доступ ко всем услугам клуба: тренажерный зал или групповые тренировки, финские сауны и хаммам.</div>
				</div>
				<div class="wrap" v-else>
					<div class="fs-22 ff-bold pb_30">Стоимость вашего <br> абонемента</div>
					<div class="fs-42 ff-bold text-gradient pb_60">{{ utilsBase.formatNumber(abonementData.basePrice, 0, ' ', ' ') }} ₽/мес.</div>
					<div
						class="present"
						v-for="(item, index) in abonementData.features"
						:key="index"
					>
						<div class="present__icon">
							<img src="/assets/img/icon/present.svg" alt="">
						</div>
						<div class="present__text">{{ item }}</div>
					</div>
					<div class="list-check">
						<div
							class="list-check__item"
							v-for="(item, index) in abonementData.options"
							:key="index"
						>
							{{ item }}
						</div>
					</div>
				</div>
			</div>
			<div class="container-btn">
				<button @click="onSubmit" class="btn btn-default">
					Попробовать
				</button>
			</div>
		</template>
	</div>
</template>