<script setup>
import {computed, ref} from "vue";
import SelectCustom from "@/components/ ListCenters/SelectCustom.vue";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {utilsBase} from "@/utils/utilsBase";
import serviceAbonement from "@/core/services/serviceAbonement";
import serviceAnalyst from "@/core/services/serviceAnalyst";
import BasePreloader from "@/components/Base/BasePreloader.vue";
import {useQuestion} from "@/composition/useQuestion";

const store = useStore();
const router = useRouter();

const {
	clubList,
	getQuestions
} = useQuestion();

const isLoading = ref(false);

const getClubsListOptions = computed(() => {
	console.log(clubList.value);
	return clubList.value.map(item => {
		return {
			name: item.name,
			value: String(item.id)
		}
	})
});

const formData = ref({
	name: '',
	phone: '',
	hall: String(store.state.currentHall.id),
	abonement_id: store.state.currentAbonement.id
});

const isDisabledButton = ref(false);

const formErrors = ref({});
const optionSelect = (option) => {
	formData.value.hall = option.value
};

const onSubmit = () => {
	isDisabledButton.value = true;
	setTimeout(() => {
		isDisabledButton.value = false;
	}, 3000);
	const keys = Object.keys(formData.value);
	
	keys.forEach(key => {
		if (formData.value[key] === '') {
			formErrors.value[key] = true;
		} else if (key === 'phone') {
			const regex = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/
			if (formData.value[key].length < 18 || !regex.test(formData.value[key])) {
				formErrors.value[key] = true;
			} else {
				delete formErrors.value[key];
			}
		} else {
			delete formErrors.value[key];
		}
	});
	
	if (utilsBase.isEmptyObject(formErrors.value)) {
		sendRequest(formData.value);
	}
}

const sendRequest = (data) => {
	serviceAbonement.sendRequest(data, () => {
		serviceAnalyst.sendEvent({
			event: 'route_to',
			data: {
				from_page_title_ru: 'Отправка заявки',
				from_page_title_en: 'BookingPage',
				to_page_title_ru: 'Финиш',
				to_page_title_en: 'FinishPage',
			}
		});
		router.push({ name: 'FinishPage' });
	}, () => {
		isDisabledButton.value = false;
	});
};

if (utilsBase.isEmptyObject(store.state.currentHall)) {
	router.push({name: 'ListCenters'});
} else {
	isLoading.value = true;
	getQuestions(() => {
		isLoading.value = false;
	});
}
</script>

<template>
	<div class="">
		<BasePreloader v-if="isLoading"/>
		<div class="container" v-else>
			<div class="wrap">
				<div class="fs-22 ff-bold pb_30">
					Здорово! Мы подобрали Вам абонемент с полным доступом<br/>
					<span class="text-gradient" v-if="store.state.finalPrice">
						за {{ utilsBase.formatNumber(store.state.finalPrice, 0, ' ', ' ') }} ₽ ежемесячно.
					</span>
				</div>
				
				<div class="c-gray fs-16 lh-base px-16 pb_30">Отличная возможность проверить, твоё или не твоё - бесплатная пробная тренировка.</div>
				
				<form class="form" action="" @submit.prevent="onSubmit">
					<div :class="['form__item', {'is-error': formErrors.name}]">
						<label for="name" class="form__label">Введите имя</label>
						<input id="name" type="text" class="form__input" placeholder="Имя*" v-model="formData.name">
					</div>
					
					<div :class="['form__item', {'is-error': formErrors.phone}]">
						<label for="phone" class="form__label">Введите телефон</label>
						<input id="phone" type="tel" class="form__input" placeholder="+7" v-mask="['+7 (###) ###-##-##']" v-model="formData.phone">
					</div>
					
					<label for="list" class="form__label">Зал для бесплатного занятия</label>
					<SelectCustom
						v-if="getClubsListOptions.length"
						:options="getClubsListOptions"
						@select="optionSelect"
						:selected="formData.hall"
					/>
					<div class="container-btn">
						<button class="btn btn-default" type="submit" :disabled="isDisabledButton">
							Записаться
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>