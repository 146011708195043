import {createRouter, createWebHistory} from 'vue-router'

import StartPage from "@/page/StartPage.vue";
import ListCenters from "@/page/ListCenters.vue";
import SubscriptionPage from "@/page/SubscriptionPage.vue";
import SurveyPageNoMoscow from "@/page/SurveyPageNoMoscow.vue";
import BookingPage from "@/page/BookingPage.vue";
import FinishPage from "@/page/FinishPage.vue";
import LayoutApp from "@/components/Layout/LayoutApp.vue";
import SurveyQuestions from "@/page/SurveyQuestions.vue";

const routes = [
	{
		path: '/',
		name: 'Layout',
		component: LayoutApp,
		redirect: {name: 'Home'},
		children: [
			{
				path: '/home',
				name: 'Home',
				component: StartPage,
			},
			{
				path: '/questions/:id',
				name: 'SurveyQuestions',
				component: SurveyQuestions,
			},
			{
				path: '/ListCenters/',
				name: 'ListCenters',
				component: ListCenters,
			},
			{
				path: '/SubscriptionPage/',
				name: 'SubscriptionPage',
				component: SubscriptionPage,
			},
			{
				path: '/SurveyPageNoMoscow/',
				name: 'SurveyPageNoMoscow',
				component: SurveyPageNoMoscow,
			},
			{
				path: '/BookingPage/',
				name: 'BookingPage',
				component: BookingPage,
			},
			{
				path: '/FinishPage/',
				name: 'FinishPage',
				component: FinishPage,
			},
		]
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

export default router
