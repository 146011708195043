<template>
	<main class="main">
		<router-view v-slot="{ Component }" ref="mainContent">
			<transition name="fade" mode="out-in">
				<component :is="Component"/>
			</transition>
		</router-view>
	</main>
</template>

<script>
export default {
	name: "LayoutApp"
}
</script>