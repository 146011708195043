<script setup>
import {onMounted} from "vue";
import {useStore} from "vuex";
import serviceAnalyst from "@/core/services/serviceAnalyst";
import router from "@/router";

const store = useStore();

onMounted(() => {
	if (!store.state.isMounted) {
		serviceAnalyst.sendEvent({
			event: 'open',
		});
		store.commit('mount')
	}
});

const onSubmit = () => {
	serviceAnalyst.sendEvent({
		event: 'route_to',
		data: {
			from_page_title_ru: 'Главная',
			from_page_title_en: 'Home',
			to_page_title_ru: 'Вопросы',
			to_page_title_en: 'Questions',
		}
	});
	router.push({ name: 'SurveyQuestions', params: { id: 0 } });
};
</script>

<template>
	<div class="bg-img">
		<div class="container">
			<div class="wrap">
				<div class="logo">
					<img src="/assets/img/logo.png" alt="">
				</div>
				<div class="ff-bold fs-22">
					Всего 4 вопроса и мы рассчитаем, сколько стоит <span class="text-gradient">безлимитный абонемент с оплатой за месяц</span> и Вашу скидку.
				</div>
			</div>
		</div>
		<div class="container-btn">
			<button class="btn btn-default" @click="onSubmit">
				Начать
			</button>
		</div>
	</div>
</template>