import {ref} from "vue";
import {useQuestion} from "@/composition/useQuestion";

export function useListCenters() {
	const {
		getQuestions
	} = useQuestion();

	const isLoading = ref(false);

	const clubsList = ref([]);
	const abonementData = ref({});

	return {
		isLoading,
		clubsList,
		abonementData,
		getQuestions
	}
}