import serviceApi from "@/core/serviceApi";

export default class serviceQuestion {
	/**
	 * Маршруты для запросов
	 * @property {String} getQuestionsList - Получить список вопросов
	 * @property {String} sendAnswer - Отправить ответ на вопрос
	 */
	static _requestRouts = {
		getQuestionsList: 'spirit/quiz/question/list',
		sendAnswer: 'spirit/quiz/answer-question',
	};

	/**
	 * Получение списка вопросов
	 * @async
	 * @param actionAfter
	 * @param actionAfterError
	 * @returns {Object}
	 */
	static async getQuestionsList(actionAfter = () => {}, actionAfterError = () => {}) {
		try {
			const {data = {}} = await serviceApi.get(this._requestRouts.getQuestionsList);

			actionAfter(data);
		}
		catch (error) {
			actionAfterError(error);
		}
	}

	/**
	 * Отправка ответа
	 * @async
	 * @param {object} formData
	 * @param actionAfter
	 * @param actionAfterError
	 * @returns {object}
	 */
	static async sendAnswer(formData, actionAfter = () => {}, actionAfterError = () => {}) {
		let postData = {
			key: formData.key,
			answer: formData.answer
		};

		try {
			let {data = {}} = await serviceApi.post(this._requestRouts.sendAnswer, postData);

			actionAfter(data);
		} catch (error) {
			actionAfterError(error);
		}
	}
}