<script setup>
// import {useRouter} from "vue-router";
import serviceAnalyst from "@/core/services/serviceAnalyst";
import serviceQuestion from "@/core/services/serviceQuestion";
import {useQuestion} from "@/composition/useQuestion";

// const router = useRouter();

const {
	changeTab
} = useQuestion();

const onSubmit = (link = null) => {
	if (link) {
		serviceAnalyst.sendEvent({
			event: 'route_back',
			data: {
				from_page_title_ru: 'Не Москва',
				from_page_title_en: 'NoMoscow',
				to_page_title_ru: 'Внешний сайт',
				to_page_title_en: 'Site',
			}
		});
		window.open(link, '_blank');
	} else {
		serviceQuestion.sendAnswer({
			key: 'is_live_in_moscow',
			answer: true
		});
		serviceAnalyst.sendEvent({
			event: 'route_to',
			data: {
				from_page_title_ru: 'Не Москва',
				from_page_title_en: 'NoMoscow',
				to_page_title_ru: 'Вопросы',
				to_page_title_en: 'Questions',
			}
		});
		changeTab(1);
		// router.push({ name: 'SurveyQuestions', params: { id: 1 } });
	}
};
</script>

<template>
	<div class="container">
		<div class="wrap">
			<div class="fs-22 ff-bold pb_40">
				24 клуба сети Spirit. Fitness находятся в Москве
			</div>
			<div class="w_100">
				<button class="btn btn-border mb_10" @click="onSubmit(null)">
					Подобрать клуб в Москве ?
				</button>
				<button @click="onSubmit('https://spiritfit.ru/')" class="btn btn-border">
					Перейти на сайт spiritfit.ru
				</button>
			</div>
		</div>
	</div>
</template>