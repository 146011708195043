<script setup>
import {ref} from "vue";
import MapList from "@/components/ ListCenters/MapList.vue";
import ListContainer from "@/components/ ListCenters/ListContainer.vue";
import {useStore} from "vuex";
import {useListCenters} from "@/composition/useListCenters";
import serviceAnalyst from "@/core/services/serviceAnalyst";
import BasePreloader from "@/components/Base/BasePreloader.vue";
import {useQuestion} from "@/composition/useQuestion";
import serviceQuestion from "@/core/services/serviceQuestion";
import {useRouter} from "vue-router";

const router = useRouter();
const store = useStore();

const {
	changeTab: routeToQuestion
} = useQuestion();

const {
	abonementData,
	isLoading,
	clubsList,
	getQuestions
} = useListCenters();

const activeTab = ref('list');

const setPrice = (data, actionAfter = () => {}) => {
	if (abonementData.value.name === 'standart') {
		store.state.currentPrice = Number(data.price);
	} else {
		store.state.currentPrice = abonementData.value.basePrice;
	}
	actionAfter();
};

const onSelectHall = (data) => {
	setPrice(data,() => {
		store.state.currentHall = data;
		const sentData = {
			key: 'select_club',
			answer: data.title
		}
		
		serviceQuestion.sendAnswer(sentData, () => {
			serviceAnalyst.sendEvent({
				event: 'select_club',
				data: {
					selectedClub: data
				}
			});
			serviceAnalyst.sendEvent({
				event: 'route_to',
				data: {
					from_page_title_ru: 'Список клубов',
					from_page_title_en: 'ListClubs',
					to_page_title_ru: 'Подписка',
					to_page_title_en: 'Subscription',
				}
			});
			
			router.push({name: 'SubscriptionPage'});
		});
	});
};

const tabsController = ref([
	{
		key: 'map',
		label: 'карта'
	},
	{
		key: 'list',
		label: 'список'
	}
]);

const changeTab = (key) => {
	activeTab.value = key;
};

getQuestions((data) => {
	const findQuestion = data.questions.find(item => item.key === 'select_club');
	clubsList.value = findQuestion.answers.map(item => {
		return {
			id: item.id,
			coords: [item.marker.latitude, item.marker.longitude],
			tel: item.phone,
			title: item.name,
			text: item.marker.address,
			price: item.price,
			features_list: item.feature_list
		}
	});
	
	isLoading.value = false;
})
</script>

<template>
	<div class="">
		<BasePreloader v-if="isLoading"/>
		<template v-else>
			<div class="tabs">
				<div
					v-for="(item, index) in tabsController"
					:key="index"
					@click="changeTab(item.key)"
					:class="[
					'tabs__title',
					{'_active': activeTab === item.key}
				]"
				>
					{{ item.label }}
				</div> 
			</div>
			<div class="tabs_content">
				<transition-group name="fade" mode="out-in" tag="div">
					<div v-if="activeTab === 'map'" class="tabs__item">
						<MapList :content="clubsList" @onSelectHall="onSelectHall"/>
					</div>
					<div v-if="activeTab === 'list'" class="tabs_item">
						<ListContainer :content="clubsList" @onSelectHall="onSelectHall"/>
					</div>
				</transition-group>
			</div>
		</template>
	</div>
</template>