export class utilsBase {
	/**
	 * Проверка на пустоту объект
	 * @param obj
	 * @returns {boolean}
	 */
	static isEmptyObject(obj) {
		for(let prop in obj) {
			if(Object.prototype.hasOwnProperty.call(obj, prop)) {
				return false;
			}
		}
		return JSON.stringify(obj) === JSON.stringify({});
	}

	/**
	 * Форматирование цифр
	 *
	 * @param {String, Number} number - значение
	 * @param {Number} decimals - сколько знаков оставлять после запятой
	 * @param {String} dec_point - какой разделить будет между целым и дробью 25.22/25,22
	 * @param {String} thousands_sep - между каждыми тысячными значениями 29 200 200/29,200,200
	 * @returns {String}
	 */
	static formatNumber = (number, decimals, dec_point, thousands_sep) => {	// Format a number with grouped thousands
		let i, j, kw, kd, km, minus;
		if (isNaN(decimals = Math.abs(decimals))) {
			decimals = 2;
		}
		if (dec_point === undefined) {
			dec_point = ',';
		}
		if (thousands_sep === undefined) {
			thousands_sep = '.';
		}
		i = parseInt(number = (+number || 0).toFixed(decimals)) + '';

		if (number.includes('-')) {
			i = i.replace('-', '');
			minus = '-';
		} else {
			minus = '';
		}

		if ((j = i.length) > 3) {
			j = j % 3;
		} else {
			j = 0;
		}

		km = (j ? i.substr(0, j) + thousands_sep : '');
		kw = i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands_sep);
		kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2) : '');
		return minus + km + kw + kd;
	}
}