<template>
	<div class="">
		<YandexMap
			:coordinates="coordinatesCenter"
			:detailed-controls="detailedControls"
			:controls="controls"
			:zoom="zoom"
			:map-type="map"
			:behaviors="['drag', 'dblClickZoom']"
		>
			<YandexClusterer :options="{ preset: 'islands#blueCircleIcon'}">
				<YandexMarker
					v-for="(item, index) in content"
					:key="index"
					:coordinates="item.coords"
					:marker-id="`1-marker-${item.id}`"
					@click="toggleBalloon(item)"
				/>
			</YandexClusterer>
		</YandexMap>
		<CustomBalloon
			v-if="balloonVisible"
			:data="balloonContent"
			@onClose="toggleBalloon"
			@onSelect="onSelect"
		/>
	</div>
</template>

<script>
import {YandexMap, YandexMarker, YandexClusterer} from 'vue-yandex-maps'
import {reactive, ref} from "vue";
import CustomBalloon from "@/components/ ListCenters/CustomBalloon.vue";

export default {
	name: "MapList",
	components: {CustomBalloon, YandexMap, YandexMarker, YandexClusterer},
	props: {
		content: { type: Array, default: () => ([]) }
	},
	setup(props, { emit }) {
		const coordinates = ref([55.777412, 37.115337]);
		const coordinatesCenter = ref([55.748354, 37.624436]);
		const controls = ref(['fullscreenControl']);
		const detailedControls = reactive({zoomControl: {position: {right: 10, top: 50}}});
		const zoom = ref(10);
		const map = ref('map');
		const behaviors = ref(['disable(ScrollZoom)', 'enable(Drag)']);
		const markerIcon = ref(
			{
				iconLayout: 'default#image',
				iconImageHref: '/public/assets/img/icon/mark.svg',
				iconImageSize: [43, 43],
				iconImageOffset: [0, 0],
			}
		);
		const clusterIcons = ref(
			{
				href: '/public/assets/img/icon/cluster.svg',
				size: [40, 40],
				// Отступ, чтобы центр картинки совпадал с центром кластера.
				offset: [-20, -20]
			});
		const name = ref('Custom');
		
		const settings = {
			apiKey: '8828fdca-2c9b-4950-a7a0-5eab6249a6ce', // Индивидуальный ключ API
			lang: 'ru_RU', // Используемый язык
			coordorder: 'latlong', // Порядок задания географических координат
		}
		
		const balloonVisible = ref(false);
		const balloonContent = ref({});
		
		const toggleBalloon = (data = null) => {
			if (data) {
				balloonContent.value = data;
				balloonVisible.value = true;
			} else {
				balloonContent.value = {};
				balloonVisible.value = false;
			}
		};
		
		const onSelect = (data) => {
			emit('onSelectHall', data);
		}
		
		return {
			coordinates,
			controls,
			detailedControls,
			settings,
			zoom,
			map,
			behaviors,
			coordinatesCenter,
			markerIcon,
			clusterIcons,
			name,
			balloonVisible,
			balloonContent,
			toggleBalloon,
			onSelect
		}
	}
}
</script>

<style>
.yandex-balloon {
	height: 200px;
	width: 200px;
}
</style>