import serviceApi from "@/core/serviceApi";

export default class serviceAbonement {
	/**
	 * Маршруты для запросов
	 * @property {String} getQuestionsList - Получить данные по абонементу
	 */
	static _requestRouts = {
		getAbonement: 'spirit/abonement',
		sendRequest: 'spirit/training/request/send',
	};

	/**
	 * Получение списка вопросов
	 * @async
	 * @param actionAfter
	 * @param actionAfterError
	 * @returns {Object}
	 */
	static async getAbonement(actionAfter = () => {}, actionAfterError = () => {}) {
		try {
			const {data = {}} = await serviceApi.get(this._requestRouts.getAbonement);

			actionAfter(this.getAbonementAfter(data));
		} catch (error) {
			actionAfterError(error);
		}
	}

	static getAbonementAfter(data) {
		const {
			id = null,
			name = '',
			title = '',
			base_price = 0,
			options = [],
			description = '',
			club_list = [],
		} = data;

		let formattedClubsList = [];

		if (club_list) {
			formattedClubsList = club_list.map(item => {
				return {
					id: item.id,
					coords: [item.marker.latitude, item.marker.longitude],
					tel: item.phone,
					title: item.name,
					text: item.marker.address,
					price: item.price,
					features_list: item.feature_list
				}
			});
		}

		const formattedAbonementData = {
			id: id,
			name: name,
			title: title,
			basePrice: base_price,
			options: options,
			description: description,
			features: formattedClubsList.length? formattedClubsList[0].features_list : [],
			club: formattedClubsList.length? formattedClubsList[0] : {}
		}

		return formattedAbonementData;
	}

	/**
	 * Отправка заявки
	 * @async
	 * @param {object} formData
	 * @param actionAfter
	 * @param actionAfterError
	 * @returns {object}
	 */
	static async sendRequest(formData, actionAfter = () => {}, actionAfterError = () => {}) {
		let postData = {
			name: formData.name,
			phone: formData.phone,
			club_id: formData.hall,
			abonement_id: formData.abonement_id
		};

		try {
			let {data = {}} = await serviceApi.post(this._requestRouts.sendRequest, postData);

			actionAfter(data);
		} catch (error) {
			actionAfterError(error);
		}
	}
}