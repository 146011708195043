import {computed, ref} from "vue";
import serviceQuestion from "@/core/services/serviceQuestion";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";

export function useQuestion() {
	const store = useStore();
	const router = useRouter();
	const route = useRoute();

	const isLoading = ref(true);
	const clubList = ref([]);

	const currentTab = computed(() => {
		return route.params.id;
	});

	const currentPrice = computed(() => {
		return store.state.currentPrice;
	});

	const basePrice = computed(() => {
		return store.state.startPrice
	});

	const questions = ref([]);
	const answers = ref({});

	const getQuestions = (actionAfter = () => {}) => {
		serviceQuestion.getQuestionsList((data) => {
			const findClubs = data.questions.find(item => item.key === 'select_club').answers;

			clubList.value = findClubs;
			store.state.startPrice = data.base_price;
			store.state.currentPrice = data.base_price;
			questions.value = data.questions;
			isLoading.value = false;
			actionAfter(data);
		});
	};

	const sendAnswer = (key, actionAfter = () => {}) => {
		const formattedData = {
			key: key,
			answer: answers.value[key]
		};

		serviceQuestion.sendAnswer(formattedData, () => {
			actionAfter();
		});
	};

	const changeTab = (index) => {
		router.push({ name: 'SurveyQuestions', params: { id: Number(index) }});
	};

	return {
		isLoading,
		currentPrice,
		basePrice,
		questions,
		answers,
		clubList,
		getQuestions,
		sendAnswer,
		currentTab,
		changeTab
	}
}