<template>
	<div class="map-card">
		<div class="map-card__header">
			<h4 class="map-card__title">{{ getTitle }}</h4>
			<div class="map-card__close" @click="onClose">
				<img src="/assets/img/icon/close.svg" alt="Icon Close">
			</div>
		</div>
		<div class="map-card__description">{{getText}}</div>
		<a :href="'tel:' + getTel.replace(/[^+\d]/g, '')" class="map-card__link">{{getTel}}</a>
		<button class="btn btn-default" @click="onSelect">
			Выбрать
		</button>
	</div>
</template>

<script>
import {computed} from "vue";

export default {
	name: "CustomBalloon",
	props: {
		data: { type: Object, default: () => ({}) }
	},
	setup(props, { emit }) {
		const getData = computed(() => {
			const { data = {} } = props;
			return data;
		});
		
		const getTitle = computed(() => {
			const { title = '' } = getData.value;
			return title;
		});
		
		const getText = computed(() => {
			const { text = '' } = getData.value;
			return text;
		});
		
		const getTel = computed(() => {
			const { tel = '' } = getData.value;
			return tel;
		});
		
		const onClose = () => {
			emit('onClose');
		}
		
		const onSelect = () => {
			emit('onSelect', getData.value);
		}
		
		return {
			getTitle,
			getText,
			getTel,
			onClose,
			onSelect
		}
	}
}
</script>