<template>
	<div class="list__item"
	     @click="visibleClick"
	>
		<h4 class="list__title">{{ title }}</h4>
		<div class="list__text">{{ text }}</div>
		<a :href="'tel:' + tel.replace(/[^+\d]/g, '')" class="list__link">
			{{ tel }}
		</a>
		<div class="circle">
			<div class="circle__border"></div>
		</div>
	</div>
</template>

<script>
import {ref} from "vue";

export default {
	name: "ListItem",
	props: {
		title: {
			type: String,
			default: ''
		},
		text: {
			type: String,
			default: ''
		},
		tel: {
			type: String,
			default: ''
		},
	},
	setup(props, context) {
		
		const activeItem = ref(false);
		const visibleClick = () => {
			context.emit('visibleClick');
		}
		
		return {
			visibleClick,
			activeItem
		}
	}
}
</script>

<style scoped>

</style>