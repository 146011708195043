const tg = window.Telegram.WebApp;

export default {
    tg,
    initData() {
        return tg.initData;
    },
    close() {
        return tg.close;
    },
    setBackButton(data) {
        return tg.BackButton.isVisible = data;
    },
    setClickBackButton(callback) {
        return tg.BackButton.onClick(callback);
    },
    offClickBackButton(callback) {
        return tg.BackButton.offClick(callback);
    },
    openExpand() {
        return tg.expand();
    }
//     openLink(url) {
//         return tg.openLink(url);
//     },
// 	showPopup(params, callback) {
// 		// params[, callback]
// 		tg.showPopup(params, callback)
// 	},
// 	setMainButton(text) {
// 		tg.MainButton.text = text;
// 		tg.MainButton.isVisible = true;
// 		tg.MainButton.onClick(() => {
// 			tg.openLink('https://pay1time.com/pay/12506748');
// 		})
// 	}
}
