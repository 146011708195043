<script setup>
import {useStore} from "vuex";
import {useRouter} from "vue-router";
// import {utilsBase} from "@/utils/utilsBase";
import {useQuestion} from "@/composition/useQuestion";
import {computed, ref} from "vue";
import serviceAnalyst from "@/core/services/serviceAnalyst";
import BasePreloader from "@/components/Base/BasePreloader.vue";
import {utilsBase} from "@/utils/utilsBase";

const {
	isLoading,
	questions,
	answers,
	sendAnswer,
	getQuestions,
	currentTab,
	changeTab,
} = useQuestion();

const store = useStore();
const router = useRouter();
const isChanging = ref(false);

const onSubmit = (key, value = null, index) => {
	isChanging.value = true;
	if (value !== null) {
		answers.value[key] = value;
	}
	
	if (key === 'select_club') {
		serviceAnalyst.sendEvent({
			event: 'route_to',
			data: {
				from_page_title_ru: 'Вопросы',
				from_page_title_en: 'Questions',
				to_page_title_ru: 'Список клубов',
				to_page_title_en: 'ListCenters',
			}
		});
		router.push({name: 'ListCenters'});
	} else {
		sendAnswer(key, () => {
			if (key !== 'choose-club') {
				serviceAnalyst.sendEvent({
					event: 'answer_to_question',
					data: {
						key: key,
						title: questions.value.find(item => item.key === key).name,
						answer: answers.value[key]
					}
				});
			}
			
			if (key === 'is_live_in_moscow' && !answers.value[key]) {
				serviceAnalyst.sendEvent({
					event: 'route_to',
					data: {
						from_page_title_ru: 'Вопросы',
						from_page_title_en: 'Questions',
						to_page_title_ru: 'Не Москва',
						to_page_title_en: 'NoMoscow',
					}
				});
				router.push({name: 'SurveyPageNoMoscow'});
			} else {
				changeTab(index);
				isChanging.value = false;
			}
		});
	}
};

const widthProgressBar = computed(() => {
	return (Number(currentTab.value) + 1) * 100 / questions.value.length;
});

// if (utilsBase.isEmptyObject(answers.value)) {
// 	router.push({ name: 'SurveyQuestions', params: { id: 0 }});
// }

const getCurrentHall = computed(() => {
	return store.state.currentHall;
});

const isCurrentHallEmpty = computed(() => {
	return !utilsBase.isEmptyObject(getCurrentHall.value) && Number(currentTab.value) === 3;
})

getQuestions();
</script>

<template>
	<div class="">
		<BasePreloader v-if="isLoading"/>
		<template v-else>
			<div class="container">
				<div class="scroll-bar">
					<div class="scroll-bar__container" :style="{ width: widthProgressBar + '%'}"></div>
				</div>
				<div class="count">{{ (Number(currentTab) + 1) + '/' + questions.length }}</div>
				<div class="wrap">
					<div class="w_100">
						<div class="fs-22 ff-bold pb_40">{{ questions[currentTab].name }}</div>
						<div class="select is-editable" v-if="isCurrentHallEmpty" @click="$router.push({name: 'ListCenters'})">
							<div class="select__title">
								{{ getCurrentHall.title }}
							</div>
						</div>
						<template v-if="questions[currentTab].answers && questions[currentTab].key !== 'select_club'">
							<button
								v-if="!isChanging"
								class="btn btn-border mb_10"
								v-for="(answer, index) in questions[currentTab].answers"
								:key="index"
								@click="onSubmit(questions[currentTab].key, answer, (Number(currentTab) + 1))"
							>
								<template v-if="typeof answer === 'boolean'">
									{{ answer? 'Да' : 'Нет' }}
								</template>
								<template v-else>
									{{ answer }}
								</template>
							</button>
						</template>
						<template v-else>
							<div class="container-btn">
								<button class="btn btn-default" @click="onSubmit(questions[currentTab].key, null, (Number(currentTab)))">
									Выбрать
								</button>
							</div>
						</template>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>