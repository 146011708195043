<template>
	<div class="select">
		<div
			ref="selectTitle"
			class="select__title"
			@click="selectVisible = !selectVisible"
		>
			{{ selectedItem.name }}
		</div>
		<div class="options" v-if="selectVisible">
			<div class="options__item"
				v-for="option in options"
				:key="option.value"
				@click="selectOption(option)"
			>
				{{ option.name }}
			</div>
		</div>
	</div>
</template>

<script>
import {ref, onMounted, onBeforeUnmount, computed} from "vue";

export default {
	name: "SelectCustom",
	props: {
		options: {
			type: Array,
			default: () => ([])
		},
		selected: {
			type: String,
			default: ''
		}
	},
	setup(props, context) {
		const selectVisible = ref(false);
		const selectTitle = ref(null);
		
		const selectOption = (option) => {
			context.emit('select', option);
			selectVisible.value = false
		}
		
		const hideSelect = (e) => {
			if (!selectTitle.value.contains(e.target)) {
				selectVisible.value = false
			}
		}
		
		const selectedItem = computed(() => {
			return props.options.find(item => item.value === props.selected);
		});
		
		onMounted(() => {
			document.addEventListener('click', hideSelect, true)
		});
		
		onBeforeUnmount(() => {
			document.removeEventListener('click', hideSelect, true)
		});
		
		return {
			selectVisible,
			selectOption,
			hideSelect,
			selectTitle,
			selectedItem
		}
	}
}
</script>

<style scoped>

</style>